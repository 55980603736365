@import "fonts";
@import "variables";
@import "jquery.mCustomScrollbar.min.scss";
// подключение нужных SCSS исходников Bootstrap 4
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";

$grid-breakpoints: (
        xs: 0,
        sm: 620px,
        md: 768px,
        lg: 991px,
        xl: 1300px
);

@import "../../../node_modules/bootstrap/scss/_mixins";
@import "../../../node_modules/bootstrap/scss/_root";
@import "../../../node_modules/bootstrap/scss/_reboot";
@import "../../../node_modules/bootstrap/scss/_grid";
@import "../../../node_modules/bootstrap/scss/_tables";
@import "../../../node_modules/bootstrap/scss/_utilities";
@import "../../../node_modules/bootstrap/scss/_spinners";

$link-color: #044DE7;


$mb: #044DE7;
$sft: 'Roboto Mono', monospace;
$sfd: 'Tahoma', sans-serif;
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-arrow {
  top:50%;
  left: -9px;
  margin-top: -9px;
  position: absolute;
  z-index: 1;
  padding: 0;
  width: 32px;
  height: 18px;
  border:none;
  outline: none;
  background: transparent;
  &:focus {
    outline: none;
  }
  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 18px;
    fill:#AFAFAF;
  }
  &.slick-next {
    left: auto;
    right: -9px;
    svg {
      transform: rotate(180deg);
    }
  }
}
input::-ms-clear {
  display: none;
}
html {
  position: relative;
}
body {
  font: 18px/1.21 $sft;
  font-weight: 400;
  background: #fff;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
h1, h2, h3, h4, h5, h6 {
  font-family: $sfd;
  font-weight: 600;
  color:#000;
}

h1 {
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 30px;
}

h2 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 28px;
}

h3 {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 26px;
}

h4 {
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 24px;
  letter-spacing: .6px;
}

h5 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 18px;
}

h6 {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
}
b {
  font-weight: 500;
  display: inline-block;
  line-height: 1;
}
strong {
  font-weight: 600;
}
ul {
  padding:0;
  list-style: none;
  li {
    margin-bottom: 20px;
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius:5px;
      display: inline-block;
      vertical-align: middle;
      background: #000;
      margin-right: 8px;
    }
  }
}
img {
  max-width: 100%;
}
p {
  margin-bottom: 0;
}
.container {
  max-width: 1920px;
}
a {
  color:$link-color;
  text-decoration: underline;
  &:hover {
    color:$link-color;
    text-decoration: none;
  }
}
.hb-menu {
  li {
    > a span {
      display: none;
    }
  }
}
.placeholder-input {
  position: absolute;
  left: 31px;
  top: 20px;
  font-family: $sfd;
  font-size: 14px;
  color: #666666;
  svg {
    max-width: 21px;
    max-height: 20px;
    margin-right: 17px;
    fill:#707070;
  }
  .placeholder-input-text {
    position: relative;
    background: #fff;
    top: 0;
    left: 0;
    transition: all .2s linear;
    padding: 0 5px;
    line-height: 1.5;
  }
}
input:invalid, textarea:invalid {
  &::-webkit-input-placeholder { /* Edge */
    opacity: 0;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: 0;
  }

  &::placeholder {
    opacity: 0;
  }
}

input:-moz-submit-invalid, textarea:-moz-submit-invalid {
  &::-webkit-input-placeholder { /* Edge */
    opacity: 0;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: 0;
  }

  &::placeholder {
    opacity: 0;
  }
}

input:-moz-ui-invalid, textarea:-moz-ui-invalid {
  &::-webkit-input-placeholder { /* Edge */
    opacity: 0;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: 0;
  }

  &::placeholder {
    opacity: 0;
  }
}
.form-field-fill {
  input[type='text'],
  input[type='tel'],
  input[type='email'],
  textarea {
    & ~ .placeholder-input {
      .placeholder-input-text {
        background: #fff;
        top: -30px;
        left: 0;
      }
    }
  }
  &.placeholder-input-icon {
    input[type='text'],
    input[type='tel'],
    input[type='email'],
    textarea{
      & ~ .placeholder-input {
        .placeholder-input-text {
          background: #fff;
          top: -30px;
          left:-38px;
        }
      }
    }
  }
}
input[type='text'],
input[type='tel'],
input[type='email'],
textarea{
  width: 100%;
  font: 14px/30px $sfd, sans-serif;
  background: #fff;
  border: 1px solid #CECECE;
  border-radius: 5px;
  color:#666666;
  padding: 0 10px 0 24px;
  height: 63px;
  box-shadow: none;
  &:focus {
    outline: none;
    border-color: $mb;
    & ~ .placeholder-input {
      .placeholder-input-text {
          top: -30px;
          left:0;
      }
    }
  }
  &.invalid {
    border: 1px solid #DF2828;
    box-shadow: none;
  }
}

textarea {
  height: 252px;
  padding-top: 12px;
}
.placeholder-input-icon {
  input,
  textarea {
    padding-left: 67px;
    &:focus {
      & ~ .placeholder-input {
        .placeholder-input-text {
          top: -30px;
          left:-38px;
        }
      }
    }
  }
}
.form-field {
  margin-bottom: 16px;

  position: relative;
  cursor: pointer;
  small {
    font: 14px/1.2 $sfd;
    color:#676767;
    padding: 16px 0 2px;
    display: block;
  }
  input[type="radio"] {
    display: none;
    &:checked ~ .form-field-tit {
      span {
        border-color: $mb;
      }
    }
  }
  input[type="checkbox"] {
    position: absolute;
    width: 20px;
    height: 20px;
    opacity: 0;
    & ~ .form-field-tit {
      span {
        transition: all .3s linear;
        position: relative;
        &:after {
          content:'';
          opacity: 0;
          height: 20px;
          width: 19px;
          background: url(../img/checked.svg) no-repeat 0 0;
          position: absolute;
          left: -2px;
          top: -3px;
          background-size: 100%;
        }
      }
    }
    &:checked ~ .form-field-tit {
      span {
        border-color: transparent;
        &:after {
          opacity: 1;
        }
      }
    }
    &.invalid  ~ .form-field-tit{
      span {
        border-color: #df2828;
        &:after {
          opacity: 0;
        }
      }
    }
  }
  input[type="radio"] ~ .form-field-tit {
    span {
      position: absolute;
      left: 0;
      top: 2px;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      border: 3px solid #D5D5D5;
    }
  }
  input[type="checkbox"] ~ .form-field-tit {
    padding: 0 0 0 34px;
    span {
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border: 3px solid #D5D5D5;
    }
  }
  .form-field-tit {
    position: relative;
    padding: 0 0 0 29px;
    font: 16px/1.2 $sfd;
    display: block;
    a {
      color:#000;
    }
  }
  &.error {
    input[type='text'],
    input[type='tel'],
    input[type='email'],
    textarea{
      width: 100%;
      font: 14px/30px $sfd, sans-serif;
      background: #fff;
      border: 1px solid #CECECE;
      border-radius: 5px;
      color:#666666;
      padding: 0 10px 0 24px;
      height: 63px;
      box-shadow: none;
      &:focus {
        border-color: $mb;
        & ~ .placeholder-input {
          .placeholder-input-text {
            top: -30px;
            left:0;
          }
        }
      }
    }
  }
}
.form-field-radios {
  margin-bottom: 18px;
}
.def_ul {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    margin: 0;
    &:after,
    &:before {
      display: none;
    }
  }
}

.transition {
  transition: all .3s linear;
}

button.btn, a.btn, .btn {
  font-size: 16px;
  line-height: 19px;
  color:#fff;
  background: #fff;
  display: inline-block;
  border-radius: 31.5px;
  overflow: hidden;
  text-decoration: none;
  position: relative;
  padding: 9px 22px 13px 25px;
  outline:none;
  box-shadow: none;
  border: solid 1px transparent;
  span {
    display: block;
    padding-right:29px;
    position: relative;
    z-index:1;
  }
  &:focus {
    box-shadow: none;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: $mb;
    border-radius: 31.5px;
    transition:all .3s linear;
  }
  svg {
    fill:#fff;
    width: 15px;
    height: 11px;
    position: absolute;
    right: 0;
    top:50%;
    transform: translateY(-50%);
  }
  &:hover {
    color: $mb;
    background: #fff;
    border-color: $mb;
    svg {
      fill: $mb;
    }
    &:before {
      left:-100%;
    }
  }
  &.btn-form {
    font-weight: 400;
    font-size: 16px;
    padding: 19px 63px 20px;
    min-width: 315px;
    span {
      padding-right:45px;
    }
  }
  &.btn2 {
    border: solid 1px #A9C5FF;
    color:$mb;
    svg {
      fill:$mb;
    }
    &:before {
      left: -100%;
    }
    &:hover {
      border-color: transparent;
      color:#fff;
      svg {
        fill:#fff;
      }
      &:before {
        left:0;
      }
    }
  }
}


.container0 {
  padding: 0;
}
header.header-fixed {
  position: fixed;
  width:100%;
  z-index: 100;
  background: #fff;
  opacity:0;
  top: -92px;
  &.up {
    top:0;
    opacity:1;
  }
  .logo {
    min-width: 120px;
    padding: 10px 0 10px;
    cursor: pointer;
    img {
      max-width: 120px;
    }
  }
  .top-contact a {
    font-size: 18px;
  }
  .top-contact {
    font-size: 12px;
    padding: 5px 15px 5px 15px;
  }
  .top-lk--btn svg {
    width: 30px;
    height: 30px;
  }
}
//header
header {
  position:relative;
  z-index:100;
  transition: all .3s linear;
}
.site-wr {
  position:relative;
  overflow: hidden;
  min-height: 320px;
  padding:0 0 0;
}
.header-top-l {
  padding: 0 0 0 23px;
  border-bottom: 1px solid #B9B9B9;
}
.logo {
  min-width: 120px;
  padding: 18px 0 15px;
  cursor: pointer;
}
.top-contact {
  border-left: 1px solid #CDCDCD;
  padding: 18px 31px 21px 30px;
  font-size: 14px;
  margin-left: 23px;
  svg {
    width: 22px;
    height: 22px;
    margin-top: 7px;
    margin-right: 32px;
  }
  a {
    font-size: 22px;
    color:#000;
    text-decoration: none;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 5px;
    cursor: default;
  }
}

.top-lk--btn {
  font-size: 14px;
  line-height: 17px;
  color:#000;
  width: 92px;
  height: 100%;
  box-shadow: inset 1px 0 0 0 #CDCDCD;
  cursor: pointer;
  svg {
    fill: #000;
    width: 38px;
    height: 38px;
    margin-bottom: -4px;
  }
  &:hover ~ .js_body {
    right:0;
  }
  &.active, &:hover {
    background: $mb;
    color:#fff;
    box-shadow: none;
    svg {
      fill: #fff;
    }
  }
}
.top-lk-body {
  a {
    display: block;
    color:#000;
    text-decoration: none;
    font: 18px/41px $sfd;
    min-width: 161px;
    & + a {
      border-top: 1px solid #E2E2E2;
    }
    &:hover {
      color:$mb;
    }
  }
}
.top-lk--btn ~ .js_body:hover {
  right:0;
}
.js_title ~ .js_body {
  right: -333px;
  display: block !important;
  position: absolute;
  top: 100%;
  padding: 10px 14px 9px;
  background: #fff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.25);
}

.js_title.active ~ .js_body {
  right:0;
}
.top_menu {
  ul {
    padding: 0 18px;
    li {
      font-size: 18px;
      line-height:19px;
      margin: 0 22px 0 21px;
      a {
        text-decoration: none;
        display: block;
        color:#000;
        position: relative;
        &:after{
          content: attr(data-name);
          white-space: nowrap;
          position: absolute;
          overflow: hidden;
          top: 0;
          left: 0;
          width: 0;
          color: $mb;
        }
        &:hover {
          &:after {
            transition: all .3s linear;
            width: 100%;
          }
        }
      }
    }
  }
}

.modal-popup {
  display: none;
  max-height: 100vh;
  overflow-x: hidden;
  max-width: 623px;
  background: #fff;
  box-shadow: 0px 0px 66px rgba(0, 0, 0, 0.13);
  border-radius: 40px;
  padding: 40px 60px 43px;
  box-sizing: border-box;
  .row {
    margin-left: -7px;
    margin-right: -7px;
    backface-visibility: hidden;
    transform: translateZ(0);
  }
  [class^="col"] {
    padding-left: 7px;
    padding-right: 7px;
  }
}
.modal-popup-register {
  max-width: 764px;
  a.btn, .btn {
    &.btn-form {
      padding: 19px 29px 20px 44px;
      min-width: 218px;
    }
  }
  .btn-form-wr {
    padding-top: 0;
    margin-top: 2px;
  }
  .modal-btn-note {
    padding-top: 20px;
  }
}
.modal-h1 {
  font-size: 36px;
  line-height: 42px;
  font-weight:700;
  margin-bottom: 35px;
}
.btn-form-wr {
  padding-top: 27px;
}
.modal-btn-note {
  padding-top: 31px;
  font-family: $sfd;
  font-size: 15px;
  line-height: 16px;
}
.modal-popup-close {
  position: absolute;
  top: 29px;
  right: 31px;
  width: 30px;
  height: 30px;
  background: url("../img/close.svg") no-repeat 0 0;
  background-size: 100%;
  cursor:pointer;
}
.madal-note {
  font: 14px/21px $sfd;
  padding: 0 0 14px;
  a {
    color:#000;
  }
}
.form-field-maintit {
  font: 18px/21px $sfd;
  font-weight: 700;
  padding: 7px 0 18px;
}


//index

.first-section {
  max-width: 1838px;
  padding-top:45px;
  padding-bottom: 152px;
}
.first-section-l {
  padding-left: 80px;
  max-width: 853px;
}
.first-section-l-tit {
  font-weight: bold;
  font-size: 48px;
  line-height: 54px;
  margin-bottom: 36px;
}
.first-section-form {
  font-size: 0;
  max-width: 638px;
  border-radius: 35px;
  .placeholder-input {
    font-family: $sft;
    font-weight: 300;
    font-size: 16px;
  }
  .form-field {
    width: 354px*100%/629px;
    margin-right: 10px;
  }
  input {
    height: 70px;
    border-radius: 35px;
  }
  .btn.btn-form, a.btn.btn-form {
    min-width: 275px;
    padding: 24px 30px 25px;
  }
}
.first-section-r-anim {
  margin-left: 40px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  img {
    max-width: 100%;
    height: auto;
  }
}

.first-section-r-anim.active {
  .fsa1 {
    left: 0;
    opacity: 1;
    transition: all .6s linear;
  }
  .fsa1-1 {
    transform: translateY(-50%) scale(1);
    transition: all .3s linear .6s;
  }
}
.fsa1 {
  position: absolute;
  left: -50px;
  opacity: .3;
  top: 0;
  width: 223px*100%/850px;
  height: 193px*100%/767px;
  background: linear-gradient(312.47deg, #9C92DB -24.25%, #C6BEF5 95.08%);
  border-radius: 90px 0px 0px 90px;
  transition: all 0s linear;
}
.fsa1-1 {
  position: absolute;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translateY(-50%) scale(0);
  left: 36px*100%/223px;
  width: 116px*100%/223px;
  height: 116px*100%/193px;
  border-radius: 116px;
  background: #fff;
  transition: all 0s linear;
  img {
    max-width: 66%;
  }
}
.first-section-r-anim.active {
  .fsa2 {
    top: 0;
    opacity: 1;
    transition: all .6s linear .4s;
  }
  .fsa2-1 {
    opacity: 1;
    transition: all .3s linear 1s;
  }
}
.fsa2 {
  position: absolute;
  top: -100px;
  opacity: 0;
  left: 223px*100%/850px;
  width: 193px*100%/850px;
  height: 193px*100%/767px;
  border-radius: 193px;
  background: linear-gradient(146.79deg, #FFE4D9 6.14%, #FFD8B5 80.22%), #FFCFC1;
  transition: all 0s linear;
}
.fsa2-1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 63px*100%/193px;
  opacity: 0;
  transition: all 0s linear;
}
.first-section-r-anim.active {
  .fsa3 {
    transform: scale(1);
    opacity: 1;
    transition: all .8s linear .2s;
  }
}
.fsa3 {
  opacity: 0;
  position: absolute;
  transform-origin: 100% 0;
  transform: scale(.1);
  top: 0;
  left: 416px*100%/850px;
  width: 193px*100%/850px;
  height: 193px*100%/767px;
  background: linear-gradient(42.29deg, #E3F2F9 4.03%, #C8E4F0 92.37%);
  border-radius: 0 0 0 133px;
  transition: all 0s linear;
}
.first-section-r-anim.active {
  .fsa4 {
    left: 609px*100%/850px;
    opacity: 1;
    transition: all .6s linear .4s;
  }
}
.fsa4 {
  position: absolute;
  top: 0;
  left: 800px;
  opacity: 0;
  width: 241px*100%/850px;
  height: 193px*100%/767px;
  border-radius: 0 0 0 133px;
  transition: all 0s linear;
}
.fsa4-1 {
  position: absolute;
  width: 86px*100%/241px;
  top:50%;
  transform: translateY(-50%);
  left: 53px*100%/241px;
}

.first-section-r-anim.active {
  .fsa5 {
    transform: scale(1);
    opacity: 1;
    transition: all .8s linear;
  }
}
.fsa5 {
  opacity: 0;
  position: absolute;
  top: 193px*100%/767px;
  transform: scale(0);
  left: 0;
  width: 223px*100%/850px;
  height: 193px*100%/767px;
  background: linear-gradient(142.89deg, #FFE4D9 6.14%, #FFD8B5 80.22%), #FFCFC1;
  transition: all 0s linear;
}
.first-section-r-anim.active {
  .fsa6 {
    opacity: 1;
    transition: all .5s linear .5s;
  }
}
.fsa6 {
  opacity: 0;
  position: absolute;
  top: 193px*100%/767px;
  left: 223px*100%/850px;
  width: 295px*100%/850px;
  height: 193px*100%/767px;
  transition: all 0s linear;
}
.first-section-r-anim.active {
  .fsa7 {
    opacity: 1;
    transition: all .6s linear .4s;
  }
}
.fsa7 {
  opacity: 0;
  position: absolute;
  top: 193px*100%/767px;
  left: 518px*100%/850px;
  width: 332px*100%/850px;
  height: 193px*100%/767px;
  background: #AEDCDA;
  border-radius: 126px;
  transition: all 0s linear;
}

.fsa8 {
  position: absolute;
  top: 386px*100%/767px;
  left: 0;
  width: 223px*100%/850px;
  height: 189px*100%/767px;
}

.first-section-r-anim.active {
  .fsa9 {
    left: 223px*100%/850px;
    opacity: 1;
    transition: all .6s linear;
  }
}
//вторая волна
.first-section-r-anim.active {
  .fsa9-1 {
    transform: scale(1);
    transition: all .4s linear 1.4s;
  }
}
.fsa9 {
  opacity: 0;
  position: absolute;
  top: 386px*100%/767px;
  left: 412px*100%/850px;
  width: 189px*100%/850px;
  height: 189px*100%/767px;
  background: linear-gradient(146.79deg, #FFE7DA 6.14%, #FFCDB0 80.22%);
  transition: all 0s linear;
}
//вторая волна
.fsa9-1 {
  transform: scale(0);
  position: absolute;
  left: 0;
  top: 0; 
  height: 100%;
  width: 100%;
  border-radius: 189px;
  background: #FFE8E0;
  transition: all 0s linear;
  img {
    max-width: 85px*100%/189px;
  }

}
.first-section-r-anim.active {
  .fsa10 {
    left: 411px*100%/850px;
    opacity: 1;
    transition: all .6s linear;
  }
}
//вторая волна
.first-section-r-anim.active {
  .fsa10-1 {
    transform: scale(1);
    transition: all .4s linear 1.6s;
  }
}
.fsa10 {
  opacity: 0;
  position: absolute;
  top: 386px*100%/767px;
  left: 223px*100%/850px;
  width: 189px*100%/850px;
  height: 189px*100%/767px;
  background: #DCECFC;
  transition: all 0s linear;
}
//вторая волна
.fsa10-1 {
  transform: scale(0);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 189px;
  background: #7284CA;
  opacity: 0.4;
  transition: all 0s linear;
  img {
    max-width: 60px*100%/189px;
  }

}
.fsa11 {
  position: absolute;
  top: 386px*100%/767px;
  left: 601px*100%/850px;
  width: 249px*100%/850px;
  height: 189px*100%/767px;
}
.first-section-r-anim.active {
  .fsa11-1{
    right: 0;
    opacity: 1;
    transition: all .5s linear .2s;
  }
}
.fsa11-1 {
  opacity: 0;
  position: absolute;
  right: -150px;
  top: 0;
  height: 100%;
  width: 153px*100%/249px;
  background: linear-gradient(302.67deg, #9C92DB -24.25%, #C6BEF5 95.08%);
  border-radius: 0px 90px 90px 0px;
  transition: all 0s linear;
}
//вторая волна
.first-section-r-anim.active {
  .fsa11-2{
    transform: scale(1) translateY(-50%);
    opacity: 1;
    transition: all .4s linear 1.8s;
  }
}
//вторая волна
.fsa11-2 {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 50%;
  transform: scale(0) translateY(-50%);
  width: 118px*100%/249px;
  height: 118px*100%/189px;
  border-radius: 118px;
  background: #FFD87B;
  transition: all 0s linear;
  img {
    max-width: 67px*100%/118px;
  }
}

.first-section-r-anim.active2 {
  .fsa12 {
    opacity: 1;
    border-radius: 0px 0px 133px 0px;
    box-shadow: 0px 22px 39px rgba(0, 0, 0, 0.05);
    transition: all .5s linear;
  }
}
.fsa12 {
  opacity: 0;
  position: absolute;
  top: 575px*100%/767px;
  left: 0;
  width: 223px*100%/850px;
  height: 192px*100%/767px;
  background: linear-gradient(49.76deg, #C2E6E6 3.5%, #A3D5D4 97.25%);
  box-shadow: none;
  border-radius: 0;
  transition: all 0s linear;
}
.fsa12-1 {
  position: absolute;
  right: 0;
  top: 0;
  width: 116px*100%/223px;
  height: 116px*100%/192px;
  background: #fff;
  border-radius:116px;
}
.first-section-r-anim.active3 {
  .fsa12-1-tumbler:after {
    left: auto;
    right: 0;
  }
}
.fsa12-1-tumbler {
  border: 1px solid #D1D1D1;
  box-sizing: border-box;
  border-radius: 22px;
  width: 43px*100%/116px;
  height: 23px*100%/116px;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 23px*100%/43px;
    height: 23px*100%/23px;
    background: #246DB0;
    border-radius: 22px;
    transition:all .3s linear;
  }
}
.fsa13 {
  position: absolute;
  top: 575px*100%/767px;
  left: 223px*100%/850px;
  width: 378px*100%/850px;
  height: 192px*100%/767px;
}
.fsa13-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 37px*100%/378px;
  max-width: 103px*100%/378px;
}
.fsa14 {
  position: absolute;
  top: 575px*100%/767px;
  left: 601px*100%/850px;
  width: 249px*100%/850px;
  height: 192px*100%/767px;
  background: linear-gradient(152.45deg, #FFE4D9 6.14%, #FFD8B5 80.22%), #FFCFC1;
}

.first-section-r-anim.active {
  .fsa14-1{
    left: 50%;
    opacity: 1;
    transition: all .4s linear .4s;
    img {
      transform: scale(1);
      transition: all .2s linear .8s;
    }
    & + .fsa14-1 {
      left: 50%;
    }
  }
}
.fsa14-1 {
  position: absolute;
  opacity: 0;
  width: 96px*100%/249px;
  height: 96px*100%/192px;
  border-radius: 94px;
  background: #fff;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  transition: all 0s linear;
  img {
    max-width: 38px*100%/96px;
    transform: scale(0);
    transition: all 0s linear;
  }
  & + .fsa14-1 {
    top: auto;
    bottom: 0;
    left: 100%;
    img {
      max-width: 34px*100%/96px;
    }
  }
}
.first-section-r-anim.active {
  .fsa15{
    transform: scale(1);
    transition: all .2s linear 2.6s;
    img {
      opacity: 1;
      transition: all .2s linear 2.8s;
    }
  }
}
.fsa15 {
  position: absolute;
  top: 197px*100%/767px;
  left: 296px*100%/850px;
  width: 192px*100%/850px;
  height: 176px*100%/767px;
  background: url("../img/cl.svg") no-repeat 50% 50%;
  background-size: 100%;
  transform: scale(0);
  transition: all 0s linear;
  img {
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10px*100%/176px;
    max-width: 144px*100%/192px;
    transition: all 0s linear;
  }
}
.fsa16 {
  position: absolute;
  width: 276px*100%/850px;
  height: 206px*100%/767px;
  background-size: 100%;
  left: 44px*100%/850px;
  bottom: 270px*100%/767px;
  transform: translateX(-50px) translateY(50px);
  opacity:0;
  transition: all 0s linear;
  svg {
    max-width: 100%;
    height: 100%;
  }
}
.first-section-r-anim.active {
  .fsa16 {
    opacity: 1;
    transform: translateX(0) translateY(0);
    transition: all .6s linear 2.6s;
  }
  .path_m {
    animation: dash 1s 2.3s forwards alternate ease-in;
  }
}
.path_m {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
@keyframes dash {
  to {
    stroke-dashoffset: 2000;
  }
}

.first-section-l-body {
  font: 20px/37px $sfd;
  margin-bottom: 66px;
}
.qw-block-wr, footer.container {
  max-width: 1680px;
}
.form-qw-wr {
  max-width: 100%;
  .row {
    padding: 0 0 0 42px;
    margin-left: -7px;
    margin-right: -7px;
    [class^="col"] {
      padding-left: 7px;
      padding-right: 7px;
    }
  }
  .modal-h1 {
    margin-bottom: 31px;
  }
  .form-field {
    margin-bottom: 13px;
  }
}
.form-qw-tit {
  padding-left: 42px;
}
.list-qw-wr {
  position: relative;
  padding-top: 52px;
  padding-bottom: 64px;
  .block-title {
    padding-left: 18px;
  }
  &:before {
    content: '';
    background: #F0F6F9;
    border-radius: 0 64px 64px 0;
    position: absolute;
    z-index: -1;
    left: -1000%;
    right: -18px;
    top: 0;
    bottom: 0;
  }
}
.block-title {
  font: 48px $sft;
  font-weight: bold;
  margin-bottom: 22px;
}
.list-qw-glider-wrapper-js {
  height:519px;
  box-sizing: border-box;
  overflow-x: hidden;
}
.list-qw-glider {
  padding:18px 31px 15px 18px;
  li {
    border: 1px solid #88B7D5;
    border-radius: 15px;
    .list-qw-glider-tit {
      display: block;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      padding:23px 70px 30px 28px;
      cursor: pointer;
      position: relative;
      span {
        display: block;
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -25px;
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 50px;
        svg {
          width: 18px;
          height: 10px;
        }
      }
    }
    .list-qw-glider-bo {
      display: none;
      font: 16px/32px $sfd;
      margin-top: -6px;
      padding:0 20px 43px 28px;
    }
    & + li {
      margin-top: 14px;
    }
    &.active {
      background: #FFFFFF;
      border: 1px solid #88B7D5;
      box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.25);
      .list-qw-glider-tit span {
        transform: rotate(180deg);
        background: #F0F6F9;
      }
      .list-qw-glider-bo {
        display: block;
      }
    }
  }
}

.slider-becouse-wr {
  max-width: 1816px;
  .slick-disabled {
    display: none !important;
  }
  ul {
    padding:0;
  }
  li {
    padding: 66px 21px 94px;
    outline: none;
    opacity:0;
    .slick-active {
      transition:opacity .5s linear;
    }
    > div {
      background: #FFFFFF;
      box-shadow: 0px 0px 66px rgba(0, 0, 0, 0.13);
      border-radius: 40px;
      padding: 47px 31px  61px 41px;
      height: 100%;
      > div {
        font-weight: 500;
        font-size: 36px;
        line-height: 54px;
        margin-bottom: 28px;
        flex: 1 1 100%;
        width:100%;
      }
      > span {
        font: 20px/31px $sfd;
      }
    }
  }
}
.slider-banner-wr {
  padding-top: 75px;
  padding-bottom: 136px;
  max-width: 1198px;
  .slick-arrow.slick-next {
    left: auto;
    right: -76px;
  }
  .slick-arrow.slick-prev {
    left: -76px;
  }
}
.slider-banner {
  li {
    padding: 47px 65px 64px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 20px;
    outline: none;
  }
}
.slider-banner-tit {
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 4px;
  max-width: 455px;
}
.slider-banner-body {
  font: 20px/33px $sfd;
  max-width: 528px;
}

.tarif-wr {
  background: url("../img/petno.svg") no-repeat 37% 0;
  max-width: 1684px;
  padding: 107px 17px 50px;
  .row {
    margin-left: -17px;
    margin-right: -17px;
    [class^="col"] {
      padding-left: 17px;
      padding-right: 17px;
    }
  }
}
.tarif-tit {
  font-size: 48px;
  line-height: 54px;
  font-weight: 700;
  margin-bottom: 38px;
}
.tarif-tab {
  max-width: 1021px;
  margin: 0 auto;
  border: 1px solid #CBCBCB;
  border-radius: 90px;
  margin-bottom: 52px;
  li {
    font-size: 16px;
    line-height: 19px;
    color: #4D4D4D;
    margin: -1px;
    padding: 21px 0 19px;
    cursor: pointer;
    &.active {
      color:#000000;
      background: #FFFFFF;
      box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.25);
      border-radius: 90px;
    }
  }
}
#modal-answer {
  max-width: 620px;
  width: 100%;
  padding: 64px 20px 43px;
  display:flex;
  flex-direction: column;
  align-items:center;
  font: 38px/42px $sft;
  font-style: normal;
  font-weight: bold;
  svg {
    margin-bottom: 26px;
  }
}
#modal-answer-content {
  max-width: 400px;
  text-align: center;
}
.tarif-tab-b {
  > li {
    display: none;
    &.active {
      display: flex;
    }
  }
}
.tarif-item-in {
  background: #FFFFFF;
  box-shadow: 0px 0px 66px rgba(0, 0, 0, 0.13);
  border-radius: 40px 40px 0px 0px;
  padding: 35px 14px 0;
  margin-bottom: 13px;
}
.tarif-item-tit {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #044DE7;
  span {
    font-weight: bold;
    color:#000;
    width: 100%;
  }
}
.tarif-item-info-tit {
  font: 18px/28px $sfd;
  font-weight: bold;
  margin-bottom: 20px;
}
.tarif-item-info-list {
  font: 16px/18px $sfd;
  li {
    position: relative;
    padding-left: 31px;
    margin-bottom: 21px;
    > div {
      padding: 9px 0 1px;
      img {
        margin-right: 7px;
        max-width: 42px;
      }
    }
    svg {
      width: 17px;
      height: 13px;
      position: absolute;
      left: 0;
      top: 5px;
      fill:$mb;
    }
  }
}
.tarif-item-info-doc-tit {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color:#9B9B9B;
  padding: 6px 0 9px;
}
.tarif-item-info-doc-list {
  li {
    padding-left: 16px;
    position: relative;
    font: 16px/20px $sfd;
    margin-bottom: 11px;
    &:before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0;
      top: 7px;
      border-radius: 8px;
      background: #000;
    }
  }
}
.tarif-item-btn {
  background: #082E40;
  border-radius: 0 0 35px 35px;
  font-size: 18px;
  line-height: 31px;
  color:#fff;
  padding: 19px 0 20px;
  cursor: pointer;
  &:hover {
    background: $mb;
  }
}
.tarif-item-info {
  background: #F3F3F3;
  border-radius: 40px 40px 0 0;
  padding: 26px 10px 11px 22px;
}
.tarif-item-tit {
  padding: 0 10px 23px 22px;
}
.otchet-wr {
  max-width: 1715px;
}
.otchet-left {
  flex: 1;
}
.otchet-right {
  max-width: 745px;
  padding: 22px 20px 20px;
  flex: 0 0 59%;
  border: 1px solid #D6D6D6;
  border-radius: 14px;
  video {
    object-fit: none;
    width: 100%;
    height: auto;
    max-height: 100%;
    box-shadow: 0px 0px 41px rgba(0, 0, 0, 0.25);
  }
}
.otchet-right-top-line {
  margin-bottom: 22px;
  span {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    background: #BDE3E3;
    & + span {
      background: #D1EEFA;
      & + span {
        background: #DCE6FE;
      }
    }
  }
  div {
    margin-left: 13px;
    background: #DFDFDF;
    border-radius: 13px;
  }
}
.otchet-right-video {
  position: relative;
}
.v-play {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: 6px solid rgba(255, 255, 255, 0.51);
  width: 119px;
  height: 119px;
  border-radius: 119px;
  cursor: pointer;
  svg {
    height: 28px;
    width: 32px;
    fill:#fff;
    margin-left: 5px;
  }
}
.otchet-left {
  max-width: 900px;
  padding-right: 30px;
}
.otchet-left-tit {
  font-size: 48px;
  line-height: 54px;
  font-weight: 700;
  margin-bottom: 29px;
}
.otchet-left-body {
  font: 20px/31px $sfd;
  margin-bottom: 55px;
}
.otchet-left-text {
  padding: 0 22px;
}
.otchet-list {
  font: 18px/27px $sfd;
  display: grid;
  grid-gap: 20px*100%/870px;
  grid-template-columns: 320px*100%/870px 220px*100%/870px 290px*100%/870px;
  li {
    position: relative;
    box-sizing: border-box;
    &.otchet-list-l1 {
      padding: 33px*100%/320px 25px*100%/320px 49px*100%/320px 65px*100%/320px;
      background: #DCEFF9;
      border-radius: 200px 0  0 200px;
      &:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        right: 45px;
        height: 16px;
        bottom: 29px;
        background: url(../img/ar-gr.svg) no-repeat 100% 0;
      }
    }
    &.otchet-list-l2 {
      padding: 33px*100%/220px 30px*100%/220px 64px*100%/220px 37px*100%/220px;
      background: #fff;
      box-shadow: inset 0 0 0 11px #EAEAEA;
      &:after {
        content: '';
        position: absolute;
        display: block;
        left: 14px;
        right: 41px;
        height: 16px;
        bottom: 29px;
        background: url(../img/ar-wh.svg) no-repeat 100% 0;
      }
    }
    &.otchet-list-l3 {
      padding: 33px*100%/290px 63px*100%/290px 39px*100%/290px 36px*100%/290px;
      background: #D0F3E9;
      opacity: 0.8;
      border-radius: 0 70px;
      &:after {
        content: '';
        position: absolute;
        display: block;
        height: 64px;
        width: 64px;
        right: 24px;
        bottom: 19px;
        border-radius: 64px;
        background: #fff url(../img/check_r.svg) no-repeat 50% 50%;
      }
    }
  }
}

.what-we-do-wr {
  max-width: 1754px;
  margin-bottom: 133px;
}
.what-we-do-l {
  flex: 1 1 1087px*100%/1754px;
  max-width: 1087px;
  padding-left: 34px;
}
a.btn-tab, .btn-tab {
  padding:13px 0 17px;
  border-radius: 35px;
  font-weight: bold;
  font-family: $sft;
  font-size: 24px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transition: all .3s linear;
  color:#000;
  margin-top: 56px;
  width: 100%;
  max-width: 403px;
}
.what-we-do-tab {
  display: grid;
  grid-gap: 38px*100%/1087px;
  grid-template-columns: repeat(4, 1fr);
  li {
    padding: 69px 30px 42px;
    cursor: pointer;
    transition: all .3s linear;
    border:solid 5px #fff;
    &.wh1 {
      background: linear-gradient(-61.76deg, #C2E6E6 3.5%, #A3D5D4 97.25%);
      border-radius: 0px 0px 100px 100px;
      &:hover, &.active {
        background: #fff;
        border-color:#C2E6E6;
      }
    }
    &.wh2 {
      background: linear-gradient(-59.01deg, #E3F2F9 4.03%, #C8E4F0 92.37%);
      &:hover, &.active {
        background: #fff;
        border-color:#E3F2F9;
      }
    }
    &.wh3 {
      background: #FED877;
      border-radius: 0 100px;
      &:hover, &.active {
        background: #fff;
        border-color:#FED877;
      }
    }
    &.wh4 {
      background: linear-gradient(-129.84deg, #FFE1D9 6.14%, #FFC4B5 80.22%), #FFCFC1;
      &:hover, &.active {
        background: #fff;
        border-color:#FFE1D9;
      }
    }
    &:hover {
      span.what-we-do-tab-icon {
        background: #F5F4F4;
      }
    }
    span {
      display: block;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;

      &.what-we-do-tab-icon {
        margin: 76px auto 0;
        width: 170px;
        height: 170px;
        border-radius: 170px;
        line-height: 170px;
        background: #fff;
        transition: all .3s linear;
        img {
          display: inline-block;
        }
      }
    }
  }
}
.what-we-do-tab-b {
  .wh1 {
    .btn-tab {
      background: linear-gradient(9.74deg, #C2E6E6 3.5%, #A3D5D4 97.25%);
      &:hover {
        background: #fff;
        box-shadow: 0 0 0 2px #42AFAD;
        color:#42AFAD;
      }
    }
  }
  .wh2 {
    .btn-tab {
      background: linear-gradient(-59.01deg, #E3F2F9 4.03%, #C8E4F0 92.37%);
      &:hover {
        background: #fff;
        box-shadow: 0 0 0 2px #C8E4F0;
        color:#C8E4F0;
      }
    }
  }
  .wh3 {
    .btn-tab {
      background: #FED877;
      &:hover {
        background: #fff;
        box-shadow: 0 0 0 2px #FED877;
        color:#FED877;
      }
    }
  }
  .wh4 {
    .btn-tab {
      background: linear-gradient(-129.84deg, #FFE1D9 6.14%, #FFC4B5 80.22%), #FFCFC1;
      &:hover {
        background: #fff;
        box-shadow: 0 0 0 2px #FFC4B5;
        color:#FFC4B5;
      }
    }
  }
}
.what-we-do-tab-b {
  display: flex;
  li {
    display: none;
    flex-direction: column;
    p {
      flex: 1;
      display: flex;
      align-items: center;
    }
    &.active {
      display: flex;
      align-items: center;
    }
  }
}
.what-we-do-tit {
  font-weight: bold;
  font-size: 48px;
  line-height: 54px;
  padding-left: 34px;
  margin-bottom: 58px;
}

.what-we-do-r {
  max-width: 461px;
  min-height: 374px;
  text-align: center;
  font: 20px/36px Tahoma,sans-serif;
  position: relative;
  display: flex;
  &:before {
    content: '';
    position: absolute;
    left: -110px;
    right: -10000%;
    top: -116px;
    bottom: -78px;
    border: 11px solid #EBEBEB;
    border-radius: 130px 0px 0px 130px;
  }
}
.what-we-do-tab-b {
  position: relative;
  z-index: 1;
}
//footer
.adaptive_table {
  overflow-y: auto;
}
footer {
  padding-top: 30px;
  padding-bottom:23px;
}
.social-link {
  font-size: 0;
  margin-right: 30px;
  a {
    text-decoration: none;
    display: inline-block;
    & + a {
      margin-left: 7px;
    }
    img {
      transition: all .3s linear;
    }
    &:hover {
      img {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(100%);
      }
    }
  }
}

.copy-right {
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  line-height: 20px;
  span {
    display: block;
    font-size:12px;
    color:#A3A3A3;
  }
  a {
    color:#A3A3A3;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.bot-contact-left {
  font-size: 14px;
  padding-left: 31px;
  svg {
    position: absolute;
    left: 0;
    top: -4px;
    width: 22px;
    height: 22px;
    margin-top: 7px;
    margin-right: 32px;
  }
  a {
    position: relative;
    padding-left: 32px;
    font-size: 22px;
    color: #000;
    text-decoration: none;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 5px;
  }
}

@media (max-width: 1838px) {
  .first-section {
    max-width: 1754px;
  }
  .first-section-l {
    padding-left: 34px;
  }
}
@media (max-width: 1700px) {
  .what-we-do-tab li span.what-we-do-tab-icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin-top: 30px;
    img {
      max-width: 60%;
      height: auto;
    }
  }
  .what-we-do-l {
    padding-right: 70px;
  }
  .what-we-do-tab li span {
    font-size: 18px;
    line-height: 20px;
  }
  .what-we-do-r::before {
    content: '';
    position: absolute;
    left: -50px;
    right: -10000%;
    top: -50px;
    bottom: -50px;
    border: 11px solid #ebebeb;
    border-radius: 100px 0 0 100px;
  }
  .what-we-do-wr {
    margin-bottom: 50px;
  }
}
@media (max-width: 1600px) {
  .header-top-l {
    padding-left:15px;
  }
  .top_menu {
    ul {
      padding: 0 8px;
      li {
        margin: 0 10px;
      }
    }
  }
  .top-contact {
    border-left: 1px solid #cdcdcd;
    padding: 18px 15px 21px 15px;
    font-size: 14px;
    margin-left: 15px;
    svg {
      margin-right: 15px;
    }
  }
  .otchet-list li.otchet-list-l3::after {
    right: 5px;
    bottom: 5px;
  }
  .otchet-list li.otchet-list-l3 {
    padding: 11.37931% 24.724% 13.44828% 12.41379%;
  }
}

@media (max-width: 1400px) {
  .top_menu {
    ul {
      padding: 0 5px;
      li {
        margin: 0 10px;
        font-size: 16px;
      }
    }
  }
  .top-contact {
    font-size: 12px;
    a {
      font-size: 18px;
    }
  }
  .otchet-left {
    padding-right: 10px;
  }
  .otchet-right {
    flex: 0 0 40%;
  }
  .otchet-list {
    font: 16px/25px $sfd;
  }
  .what-we-do-r {
    max-width: 400px;
    font: 20px/36px Tahoma,sans-serif;
  }
  .btn-tab, a.btn-tab {
    margin-top: 25px;
  }
  .what-we-do-tab li {
    padding: 69px 20px 42px;
  }
  .what-we-do-tab {
    grid-gap: 20px;
  }
  .what-we-do-tab li span {
    font-size: 18px;
    line-height: 33px;
  }
  .what-we-do-tab li span.what-we-do-tab-icon {
    margin-top: 45px;
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  .what-we-do-r {
    min-height:359px;
  }
  .tarif-wr {
    background-position: 16% 0;
  }
  .what-we-do-r::before {
    border-radius: 130px 0 0 130px;
  }
  .what-we-do-tab-b .btn-tab {
    max-width:370px;
  }
  .slider-becouse-wr li > div {
    border-radius: 40px;
    padding: 47px 28px 61px 28px;
  }
  .slider-becouse-wr li {
    padding: 66px 15px 94px;
  }
}

@media (max-width: 1300px) {
  .top_menu_burger {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  .top_menu ul li {
    font-size: 18px;
    line-height: 19px;
    margin: 0;
    padding: 15px 0;
    & + li {
      border-top: solid 1px #cdcdcd;
    }
  }
  .top_menu_burger {
    position: relative;
    cursor: pointer;
    &:after {
      content:'';
      position: absolute;
      left: 10px;
      right: 10px;
      height: 3px;
      top: 16px;
      background: #000;
      transition: all .3s linear;
    }
    &:before {
      content:'';
      position: absolute;
      left: 10px;
      right: 10px;
      height: 3px;
      bottom: 15px;
      background: #000;
      transition: all .3s linear;
    }
    span {
      position: absolute;
      height: 3px;
      background: #000;
      top: 50%;
      margin-top: -1.5px;
      left: 10px;
      right: 10px;
      &:after, &:before {
        transition: all .3s linear .3s;
        content: '';
        left: 50%;
        right: 50%;
        position: absolute;
        background: #000;
        transform-origin: 50% 50%;
        transform: rotate(45deg);
      }
      &:after{
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
      }
    }
    &.active {
      span {
        &:after, &:before {
          left: -18px;
          right: -18px;
          height: 3px;
        }
      }
      &:after, &:before, span {
        left: 50%;
        right: 50%;
      }
    }
  }

  .js_title2 ~ .js_body {
    right: -333px;
    display: block !important;
    position: fixed;
    top: 100%;
    padding: 10px 14px 9px;
    background: #fff;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.25);
  }
  .js_title2.active ~ .js_body {
    right:0;
  }

  .js_title2 ~ .js_body.top_menu {
    position: fixed;
    padding: 70px 14px 10px;
    top: 0;
    bottom: 0;
    right: auto;
    left: -333px;
    width: 320px;
    overflow-x: hidden;
  }
  .js_title2.active ~ .js_body.top_menu {
    left:0;
  }
  .slider-banner-wr {
    .slick-arrow.slick-next {
      left: auto;
      right: -7px;
    }
    .slick-arrow.slick-prev {
      left: -7px;
    }
  }
  .top_menu {
    .top_menu_burger {
      position: absolute;
      top: 9px;
      left: 15px;
    }
  }
}
@media (max-width: 1200px) {
  .slider-becouse-wr li {
    padding: 33px 33px;
  }
  .slider-becouse-wr {
    padding: 0;
  }
  .slick-arrow.slick-next {
    right: 0;
  }
  .slick-arrow.slick-prev {
    left: 0;
  }
  .slider-becouse-wr li > div {
    padding: 20px;
    box-shadow: 0 0 38px rgba(0,0,0,.13);
  }
  .slider-becouse-wr li > div > div {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .otchet-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .otchet-left {
    max-width: 100%;
    padding-right: 0;
  }
  .otchet-list {
    margin-bottom: 25px;
  }
  .what-we-do-l,
  .what-we-do-tit{
    padding-left: 0;
  }
  .what-we-do-r {
    max-width: 250px;
    font: 16px/24px Tahoma,sans-serif;
    margin-right: -10px;
  }
  .what-we-do-r::before {
    border: 5px solid #ebebeb;
    border-radius: 70px;
  }
  .first-section-l {
    padding-left: 0;
  }
  .first-section-l-tit {
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 18px;
  }
  .first-section-l-body {
    font: 17px/27px Tahoma,sans-serif;
    margin-bottom: 33px;
  }
}
@media (max-width: 1024px) {
  .first-section-form .btn.btn-form, .first-section-form a.btn.btn-form {
    min-width: 215px;
    padding: 24px 12px 25px;
  }
  .what-we-do-tab li span {
    font-size: 16px;
    line-height: 24px;
  }
  .what-we-do-tab li span.what-we-do-tab-icon {
    margin-top: 25px;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .what-we-do-r {
    min-height: 270px;
  }
}
@media (max-width: 990px) {
  header.header-fixed {
    top: -69px;
      .logo {
        min-width: 120px;
        padding: 0;
        cursor: pointer;
        img {
          max-width: 120px;
        }
      }
      .top-contact a {
        font-size: 18px;
      }
      .top-contact {
        height: 52px;
        padding: 0;
      }
    .top-contact svg {
      height: 52px;
    }
  }
  .list-qw-glider-wrapper-js {
    height:auto;
  }
  .logo {
    min-width: 120px;
    padding: 5px 0;
  }
  .top-contact {
    padding: 0;
    span {
      display: block;
      padding: 0 14px;
      cursor: pointer;
      &:hover, &.active {
        background: $mb;
        svg {
          fill: #fff;
        }
      }
    }
    svg {
      height: 68px;
      margin: 0;
    }
  }
  .top-lk--btn {
    width: 50px;
    font-size: 12px;
    svg {
      width: 30px;
      height: 30px;
      margin-bottom: -5px;
    }
  }
  .js_title3 ~ .js_body {
    right: -333px;
    display: block !important;
    position: absolute;
    top: 100%;
    padding: 10px 14px 9px;
    background: #fff;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.25);
  }
  .js_title3.active ~ .js_body {
    right:0;
  }
  .list-qw-wr {
    padding-top:30px;
    padding-bottom:30px;
    &:before {
      border-radius:30px;
    }
  }
  .block-title,
  .form-qw-wr .modal-h1 {
    font-size:35px;
    line-height: 38px;
    margin-bottom: 15px;
  }
  .tarif-tit,
  .otchet-left-tit,
  .what-we-do-tit {
    font-size:35px;
    line-height: 38px;
    margin-bottom: 15px;
  }
  .list-qw-glider {
    max-height: inherit;
    padding-left: 0;
    padding-right: 0;
  }
  .form-qw-wr {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .form-qw-tit {
    padding-left: 0;
  }
  .form-qw-wr .row {
    padding: 0;
  }
  .list-qw-wr .block-title {
    padding-left: 0;
  }
  .social-link {
    width: 50%;
    margin-right: 0;
  }
  .bot-contact-left {
    flex: 1 1 auto;
    padding: 15px 0;
  }
  .slider-banner-wr {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .slider-banner li {
    padding: 25px;
  }
  .slider-banner-tit {
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 4px;
  }
  .slider-banner-body {
    font: 16px/26px Tahoma,sans-serif;
  }
  .tarif-item, .tarif-wr {
    padding-bottom: 0;
  }
  .otchet-left-body {
    margin-bottom: 25px;
  }
  .what-we-do-l {
    flex: 1 1 100%;
    max-width: 100%;
    padding-right: 0;
  }
  .what-we-do-r {
    max-width: 100%;
    padding: 20px;
    &:before {
      display: none;
    }
  }
  .what-we-do-r {
    min-height: 100px;
  }
  #modal-answer {
    font: 28px/32px $sft;
  }
}
@media (max-width: 940px) {
  .first-section-r {
    display: none !important;
  }
  .first-section-l-wr {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .first-section {
    padding-bottom: 25px;
  }
  .first-section-l,
  .first-section-form {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .modal-popup {
    padding: 30px 15px 30px;
    border-radius: 15px;
  }
  .modal-h1 {
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  .modal-popup-close {
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
  }
  .tarif-tab li {
    width: 100%;
  }
  .tarif-tab {
    border-radius:30px;
  }
  .otchet-list {
    grid-template-columns: 100%;
    li {
       &.otchet-list-l1, &.otchet-list-l2, &.otchet-list-l3 {
         padding: 20px;
         text-align: center;
         &:after {
           display: none;
         }
       }
    }
  }
  .modal-popup, #modal-answer {
    max-width:460px;
    position:fixed !important;
    left:50% !important;
    top:50% !important;
    transform:translateX(-50%) translateY(-50%);
  }
}

@media (max-width: 700px) {
  .copy-right,
  .social-link,
  .bot-contact-left {
    text-align: center !important;
  }
  footer.container {
    padding-top: 0;
    padding-bottom: 0;
  }
  footer.container .row > .div {
    justify-content: center;
  }
  .social-link {
    width: 100%;
    padding: 15px 0;
  }
  .bot-contact-left {
    width: 100%;
    padding: 15px 0;
  }
  .what-we-do-tab {
    grid-gap: 2%;
    grid-template-columns: repeat(2,49%);
  }
  .what-we-do-tab li {
    padding: 15px 45px;
  }
  .what-we-do-tab li.wh1 {
    border-radius: 100px 0;
  }
  .what-we-do-tab li.wh2 {
    border-radius: 0 100px;
  }
  .what-we-do-tab li.wh4 {
    border-radius: 100px 0;
  }
  footer .btn2 {
    margin: 0 auto;
  }
  #modal-answer {
    font: 18px/22px $sft;
  }
}
@media (max-width: 470px) {
  .first-section-form .form-field {
    width: 100%;
    margin-right: 0;
  }
  .first-section-form {
    flex-wrap: wrap;
  }
  .modal-popup, #modal-answer {
    max-width:310px;
  }
  .btn.btn-form, a.btn.btn-form, button.btn.btn-form {
    font-weight: 400;
    font-size: 16px;
    padding: 19px 20px 20px;
    min-width: 280px;
  }
}
@media (max-width: 450px) {
  .what-we-do-tab {
    grid-gap: 5px;
    grid-template-columns: 100%;
  }
  .what-we-do-tab li.wh1,
  .what-we-do-tab li.wh3,
  .what-we-do-tab li.wh2,
  .what-we-do-tab li.wh4 {
    border-radius: 0;
  }
}
@media (max-width: 400px) {
  .btn.btn-form, a.btn.btn-form {
    min-width: 280px;
    padding-left: 0;
    padding-right: 20px;
  }
}
@media (max-width: 374px) {

}
